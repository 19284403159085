import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

type Props = {
  close: () => void;
};

const Modal: React.FC<Props> = ({ close }) => {
  return (
    <div className="modalWrap">
      <OutsideClickHandler onOutsideClick={close}>
        {/*<div className="modalWrap_textWrap">*/}
          <div className='requirement-wrapper offer'>
            <div className={`requirement-wrapper-scroll`}>
              <h3 className='requirement-title-main'>
                XIZMATDAN FOYDALANISH SHARTLARI OFERTASI
              </h3>

              <p className='requirement-text text-center'>
                <b>I. Iboralar</b>
              </p>
              <p className='requirement-text'>
                1.1. Markaz – “UNICON.UZ - Fan-texnika va marketing tadqiqotlari markazi” Mas’uliyati cheklangan
                jamiyati;
              </p>
              <p className='requirement-text'>
                1.2. Shartlar – ushbu hujjat bilan belgilangan maxfiylik siyosati va foydalanish shartlari;
              </p>
              <p className='requirement-text'>
                1.3. Foydalanuvchi –Markaz xizmatlaridan foydalanish Shartlari bilan belgilangan talablarga rioya
                etishga rozilik bildirgan yuridik yoki jismoniy shaxs;
              </p>
              <p className='requirement-text'>
                1.4. Tizim – Markaz biznes jarayonlarini raqamlashtirishga mo’ljallangan avtomatlashtirilgan axborot
                tizimi;
              </p>
              <p className='requirement-text'>
                1.5. Shaxsiy ma’lumotlar – Foydalanuvchiga (shaxsiy ma’lumot sub’yekti) bevosita yoki bilvosita aloqador
                bo’lgan har qanday ma’lumot.
              </p>

              <p className='requirement-text text-center'>
                <b>II. Shartlarni qabul qilish</b>
              </p>
              <p className='requirement-text'>
                2.1. Foydalanuvchi ushbu Tizimga kirganida, Tizim yordamida Markaz xizmatlaridan foydalanganida va ushbu
                Shartlar bilan tanishib chiqqani to’g’risida belgi qo’yganida, Foydalanuvchi ushbu Shartlarni to’liq
                qabul qilgan hisoblanadi.
              </p>
              <p className='requirement-text'>
                2.2. Ushbu Shartlarga Markaz tomonidan bir tomonlama qo’shimchalar va o’zgartirishlar kiritilishi mumkin
                va yangilangan Shartlar oldindan ogohlantirishsiz tizimga joylashtiriladi.
              </p>
              <p className='requirement-text'>
                2.3. Markaz Foydalanuvchi yoki boshqa tomonlar oldida Tizimning ishlashi, Tizimda keltirilgan xizmatlar
                yoki mahsulotlar tufayli kelib chiqadigan har qanday yo’qotishlar, shu jumladan, beparvolik yoki boshqa
                holatlar tufayli ma’lumotlarni yo’qotish, foyda yo’qotish bilan bog’liq to’g’ridan-to’g’ri va bevosita
                yo’qotishlar uchun javobgar emas.
              </p>

              <p className='requirement-text text-center'>
                <b>III. Shaxsiy ma’lumotlarni qayta ishlash</b>
              </p>
              <p className='requirement-text'>
                3.1. Foydalanuvchi ushbu Shartlarni qabul qilganida, uning shaxsiy ma’lumotlarini Tizimda to’plash,
                qayta ishlash va ishlatish haqida xabardorligini tasdiqlaydi. Shaxsiy ma’lumotlarni yig’ish, saqlash va
                qayta ishlashning maqsadi, Foydalanuvchini shaxsiylashtirilgan Tizim xizmatlari bilan ta’minlashdir,
                jumladan, shaxsiy kabinet, avtorizatsiyalash, parolini eslatishga ruxsat berish, hamda xabarnomalar
                yuborish va Foydalanuvchining talablarini bajarish kiradi.
                <br/>3.2. Tizim xizmatlaridan foydalanishni ta’minlash uchun, shaxsiy ma’lumotlarni qayta ishlashga
                Foydalanuvchining shaxsiy ma’lumotlarini yig’ish, ro’yxatdan o’tkazish, to’plash, saqlash, ulardan
                foydalanish, o’chirish bilan bog’liq har qanday harakatlar va/yoki faoliyat turlari kiradi.
              </p>
              <p className='requirement-text text-center'>
                <b>IV. Majburiyatlar</b>
              </p>
              <p className='requirement-text'>
                4.1. Foydalanuvchi ushbu Tizimdan foydalanganida o’zining harakatlari va Tizimdagi ma’lumotlarni
                ishlatish oqibatlariga to’liq javobgar bo’ladi. Tizim ma’lumotlarini Markaz roziligisiz ko’chirish, chop
                etish yoki bironta vosita orqali tarqatish, hamda Internet yoki ommaviy axborot vositalarida
                joylashtirish mumkin emas.
              </p>
              <p className='requirement-text text-center'>
                <b>V. Tizimga kirish va shaxsiy kabinetingiz xavfsizligi</b>
              </p>
              <p className='requirement-text'>
                5.1. Foydalanuvchining ruxsatidan qat’iy nazar, Shaxsiy kabinetidan har qanday foydalanishlarga
                Foydalanuvchi javobgardir. Agar, Shaxsiy kabinet va/yoki Foydalanuvchining paroli boshqa shaxs tomonidan
                foydalanilayotgani haqida shubha tug’ilsa, Foydalanuvchi darhol id.egov.uz manzili orqali o’zining
                parolini yangilashi mumkin.
              </p>
            </div>

            <div className={`requirement-wrapper-scroll`}>
              <p className='requirement-text'>
                5.2. Foydalanuvchi Tizimda bir Shaxsiy kabinetdan tashqari boshqa Shaxsiy kabinetlar bilan ro’yxatdan
                o’tmaslikka roziligini bildiradi.
              </p>

              <p className='requirement-text text-center'>
                <b>VI. Foydalanish cheklovlari</b>
              </p>
              <p className='requirement-text'>
                6.1. Foydalanuvchi Tizimidan ushbu foydalanish shartlariga muvofiq faqatgina qonuniy maqsadlar uchun
                foydalanishiga rozilik bildiradi:
              </p>
              <p className='requirement-text'>
                - Tizimni ishlashini to’xtatishga, ortiqcha yuklanishiga, zarar yetkazilishiga va boshqa salbiy
                oqibatlarga olib keladigan, hamda boshqa foydalanuvchilarning tizimdan real vaqt rejimida ishlashiga
                to’sqinlik yoki xalaqit berishga oid har qanday harakatlarni amalga oshirmaslikga;
              </p>
              <p className='requirement-text'>
                - Tizimning normal ishlashiga halaqit beradigan har qanday qurilmalar, dasturiy ta’minotlar va
                jarayonlarni qo’llamaslikga;
              </p>
              <p className='requirement-text'>
                - Virus va boshqa zararli materiallarni qo’llamaslikga;
              </p>
              <p className='requirement-text'>
                - Tizimga avtorizatsiyasiz kirishga xarakat qilmaslikka, hamda Tizimning ma’lumotlari joylashgan
                serverlar va Tizimning barcha bo’limlari uzluksiz ishlashiga halaqit va zarar bermaslikga;
              </p>
              <p className='requirement-text'>
                - Xizmatni rad etish yoki xizmat ko’rsatishni taqiqlash orqali tizimning ishlashiga to’sqinlik
                qilmaslikga;
              </p>
              <p className='requirement-text'>
                = Tizimning normal ishlashiga to’sqinlik qilmaslikga.
              </p>

              <p className='requirement-text text-center'>
                <b>VII. Shаxsiy mа’lumotlаr xаvfsizligi</b>
              </p>
              <p className='requirement-text'>
                7.1. Shaxsiy ma’lumotlarni noqonuniy xatti-harakatlardan, yo’qolish, yo’q qilish va uchinchi shaxslar
                tomonidan ruxsatsiz kirishdan himoya qilish uchun Markaz zamonaviy dasturiy ta’minot va apparatni himoya
                qilish vositalaridan hamda tashkiliy xavfsizlik choralaridan foydalanadi.
              </p>
              <p className='requirement-text'>
                7.2. Markaz Foydalanuvchining iltimosi yoki uchinchi shaxslarning da’volari bo’yicha sud yoxud huquqni
                muhofaza qiluvchi organlarning so’roviga asosan, Markazning huquqlari va qonuniy manfaatlarini
                ta’minlash yuzasidan, shuningdek qonunchilikda belgilangan boshqa hollarda Foydalanuvchining shaxsiy
                ma’lumotlariga kirish huquqiga ega.
              </p>

              <p className='requirement-text text-center'>
                <b>VIII. Cookie-fayllar</b>
              </p>
              <p className='requirement-text'>
                8.1. Tizimining ba’zi bo’limlari parol bilan himoyalangan. Foydalanuvchi Tizimning parol bilan
                himoyalangan bo’limlariga parolni qaytadan kiritishsiz kirishi uchun Tizim cookie fayllarni ishlatishi
                mumkin (cookies Foydalanuvchi kompyuterida joylashgan bo’lib uning Internet-brauzerining boshqaruvi
                ostida bo’ladi).
              </p>

              <p className='requirement-text text-center'>
                <b>IX. Shartlarni o’zgаrtirish</b>
              </p>
              <p className='requirement-text'>
                9.1. Markaz ushbu Shartlarga mustaqil ravishda o’zgartirish kiritish huquqiga ega. Shartlarning
                o’zgartirilgan versiyasi Tizimda e’lon qilingan paytdan boshlab darhol kuchga kiradi va Foydalanuvchi
                uchun majburiy hisoblanadi.
              </p>
              <p className='requirement-text'>
                9.2. Foydalanuvchilarga Shartlar o’zgarishi to’g’risida maxsus xabar berilishi mumkin, ammo bunday
                ogohlantirish talab qilinmaydi va Markaz barcha manfaatdor tomonlarni Shartlardagi o’zgarishlarni
                kuzatishga undaydi.
              </p>
              <p className='requirement-text'>
                9.3. Foydalanuvchi Shartlardagi o’zgarishlarga rozi bo’lmagan taqdirda, Tizimdan foydalanishni
                to’xtatishi mumkin.
              </p>
            </div>
          </div>
        {/*</div>*/}
      </OutsideClickHandler>
    </div>
  );
};

export default Modal;
