import React from 'react';
import {useTranslation} from "react-i18next";
import {images} from "../../images/images";

function Footer() {
  const {t} = useTranslation()
  return (
    <footer className='footer'>
      <div className="footer_container">
        <div className="box flex justify-between md:flex-wrap md:items-center">
          <div className={'box-call'}>
            <a className={'call_center'} href="http://unicon.uz/contacts" target='_blank' rel="noreferrer">{t('contact')}</a>
            <a className={'phone'} href="tel:+998712118888">{t('call_center')}: <br/> +998 71-211-88-88</a>
          </div>
          <div className='box-child' style={{color: "rgba(255,255,255,0.8)"}}>
            <div className='flex items-center gap-4 xs:flex-col'>
              <a
                href="https://metrika.yandex.ru/stat/?id=95301676&amp;from=informer"
                target="_blank" rel="noreferrer"
              >
                <img src="https://informer.yandex.ru/informer/95301676/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                     style={{width: "88px", height: "31px", border: 0}} alt="Яндекс.Метрика"
                     title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                     className="ym-advanced-informer" data-cid="95301676" data-lang="ru"/></a>
            </div>
            <a href="http://unicon.uz" target='_blank' rel="noreferrer">
              <img src={images.logoFooter} className='logo' alt="logo"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;