import React, { useState } from "react";
import Steps from "../../components/Steps/Steps";
import Arrow2 from "../../images/arrow2";
import { useTranslation } from "react-i18next";
import { createEMailLetter, fileStore } from "../../store/actions/EMailActions";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import moment from "moment";

type InputProps = {
  label: string;
  value?: string | undefined;
  defaultValue?: string | undefined;
  onChange?: any;
  disabled?: boolean;
  type?: "input" | "mask" | "mfo" | "stir";
  mask?: any;
  name?: string;
  maskChar?: any;
  sendMfo?: () => void;
  sendStir?: () => void;
  sendVatNumber?: () => void;
};
export const InputBlock: React.FC<InputProps> = ({label, value, onChange, type, defaultValue, disabled, mask, name}) => {
  const {t} = useTranslation();
  return (
    <div className="contractInfo_drop-form-block-item">
      <span className="contractInfo_drop-form-block-item-label">{label}</span>
      {type === "input" && (
        <input
          type={"text"}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          disabled={disabled}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
          required
        />
      )}
      {type === "mask" && (
        <InputMask
          name={name}
          disabled={disabled}
          mask={mask}
          value={value}
          onChange={onChange}
          style={{
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? "no-drop" : "text",
          }}
        />
      )}
      {type === "stir" && (
        <div className="profile_wrap-form-block-item_wrap">
          <InputMask
            disabled={disabled}
            mask={mask}
            value={value}
            onChange={onChange}
            style={{
              opacity: disabled ? 0.5 : 1,
              cursor: disabled ? "no-drop" : "text",
            }}
          />
          {/* <button onClick={sendStir}>{'Yangilash'}</button> */}
        </div>
      )}
    </div>
  );
};

const EMailContract = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { access } = useAppSelector((state) => state.auth);

  const contract1 = localStorage.getItem("contract");
  const count1 = localStorage.getItem("count");
  const contract = contract1 ? JSON.parse(contract1) : "";
  const count = count1 ? JSON.parse(count1) : 0;
  const rows = Array.from({ length: count });

  interface Ownership {
    contract: any;
    full_name: string;
    passport_number: string;
    position: string;
    pin: string;
    file: string;
  }

  interface FormData {
    type: string;
    mailNumber: string;
    performerFullName: string;
    performerTel: string;
    letterDate: string;
    filePdf: any;
    action_type: string;
    owner_ships: Ownership[];
  }

  let today = new Date()
  let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

  const [formData, setFormData] = useState<FormData>({
    type: "2",
    mailNumber: "",
    performerFullName: "",
    performerTel: "",
    letterDate: date.toString(),
    filePdf: null,
    action_type: "1",
    owner_ships: rows.map((_, i) => ({
      contract: null,
      full_name: "",
      passport_number: "",
      position: "",
      pin: "",
      file: "",
    }))
  });

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>, idx: number, type?: string) => {
    const file = e.target.files?.[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    const form_data = new FormData();
    form_data.append("file", file);
    form_data.append('type', type as string)
    dispatch(fileStore(access, form_data)).then((res: any) => {
      if (res) {
        if (idx + 1 > 0) {
          setFormData((prevState) => ({
            ...prevState,
            owner_ships: prevState.owner_ships.map((ship, i) =>
              i === idx
                ? { ...ship, file: res.id }
                : ship
            ),
          }));
          console.log(`File for owner_ships[${idx}] uploaded successfully:`, res?.id);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            filePdf: res?.id,
          }));
          console.log("PDF file uploaded successfully:", res?.id);
        }
      } else {
        alert("Error uploading file!");
      }
    });
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, files } = e.target;

    const match = name.match(/owner_ships\[(\d+)\]\.(.+)/);

    if (match) {
      const index = parseInt(match[1], 10);
      const key = match[2];

      setFormData((prevState) => ({
        ...prevState,
        owner_ships: prevState.owner_ships.map((ship, i) =>
          i === index
            ? { ...ship, [key]: files && files.length > 0 ? files[0] : value }
            : ship
        ),
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: files && files.length > 0 ? files[0] : value,
      }));
    }
  };

  const validation = () => {
    return (
      !formData.type ||
      !formData.mailNumber ||
      !formData.performerFullName ||
      !formData.performerTel ||
      !formData.letterDate ||
      (formData.action_type === "2" && !formData.filePdf)
      // ||
      // rows.some(
      //   (_, i) =>
      //     !formData.owner_ships[i].pin ||
      //     !formData.owner_ships[i].passport_number ||
      //     !formData.owner_ships[i].position ||
      //     !formData.owner_ships[i].full_name ||
      //     !formData.owner_ships[i].file
      // )
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const ownerShips = rows.map((_, i) => ({
      contract: contract,
      full_name: formData.owner_ships[i].full_name,
      position: formData.owner_ships[i].position,
      pin: formData.owner_ships[i].pin,
      passport_number: formData.owner_ships[i].passport_number,
      file: formData.owner_ships[i].file,
    }));

    const payload = {
      save: formData.action_type === "2" ? 1 : 0,
      contract: contract,
      type: Number(formData.type),
      letter_number: formData.mailNumber,
      // letter_date: moment(formData.letterDate).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      letter_date: new Date().toISOString(),
      action_type: Number(formData.action_type),
      performer_full_name: formData.performerFullName,
      performer_phone_number: formData.performerTel,
      file_pdf: formData.filePdf,
      owner_ships: formData.action_type === "2" ? undefined : ownerShips
    };

    dispatch(createEMailLetter(access, payload)).then((res: any) => {
      if (res) {
        if (formData?.action_type === "2") {
          navigate(`/show-request/${contract}`)
        } else {
          navigate('/emailLetterAgreement');
        }
        localStorage.setItem('payload', JSON.stringify(payload))
        // localStorage.removeItem('count');
      } else {
        alert(res?.err_msg);
      }
    });
  };

  const renderEmployeeFields = () => (
    <div>
      {rows.map((_, i) => (
        <div key={i} style={{ padding: "20px", boxShadow: "0px 4px 20px -4px rgba(0, 0, 0, 0.25)", marginBottom: "30px" }}>
          <h3 className="contractInfo_title">{t("employee_info")}</h3>
          <div style={{ display: "flex", gap: "20px", marginBottom: "1rem" }}>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`owner_ships[${i}].pin`}
                type="mask"
                mask="99999999999999"
                label={t("pasportPin")}
                value={formData.owner_ships[i].pin}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`owner_ships[${i}].passport_number`}
                type="mask"
                mask="aa9999999"
                label={t("pasportNumber")}
                value={formData.owner_ships[i].passport_number}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`owner_ships[${i}].position`}
                type="mask"
                label={t("position")}
                value={formData.owner_ships[i].position}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`owner_ships[${i}].full_name`}
                type="mask"
                label={t("employee_short")}
                value={formData.owner_ships[i].full_name}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <label className="contractInfo_drop-form-block-item-label">{t("passport_copy")} (.jpg, .jpeg)</label>
              <input name={`owner_ships[${i}].file`} type="file" accept=".jpg,.jpeg" onChange={(e) => handleFile(e, i, 'img')} required />
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <form className="container">
      <div className="request request_vps">
        <Steps activeStep={4} />
        <div className="request_content">
          <h2 className="contractInfo_title" style={{ marginBottom: "20px" }}>{t("send_letter")}</h2>

          <h3 style={{ fontWeight: "bolder", fontSize: "1rem", marginBottom: "10px" }}>{t("choose_exat")}</h3>
          <div className="flex-wrap" style={{ display: "flex", marginBottom: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: "60px" }}>
              <input id="new" style={{ marginRight: "10px" }} value="1" type="radio" onChange={handleInputChange} name="type" />
              <label htmlFor="new" style={{ fontWeight: "bolder" }}>{t("letter_type_new")}</label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input id="extension" style={{ marginRight: "10px" }} value="2" checked={formData.type === "2" ? true : false } type="radio" onChange={handleInputChange} name="type" />
              <label htmlFor="extension" style={{ whiteSpace: "nowrap", fontWeight: "bolder" }}>{t("letter_type_extension")}</label>
            </div>
          </div>

          <div style={{ display: "flex", marginBottom: "20px", gap: "30px" }}>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`mailNumber`}
                type="mask"
                label={t("letter_number")}
                value={formData.mailNumber}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <label className="contractInfo_drop-form-block-item-label">{t("letter_date")}</label>
              <input name="letterDate" style={{fontWeight: "bold"}} disabled={formData.action_type !== '2'} value={formData.letterDate} onChange={handleInputChange} type={formData.action_type !== '2' ? 'text' : 'date'} required />
            </div>
          </div>

          <div style={{ display: "flex", gap: "30px", marginBottom: "30px" }}>
            <div style={{ width: "100%" }}>
              <InputBlock
                name={`performerFullName`}
                type="mask"
                label={t("executive")}
                value={formData.performerFullName}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputBlock
                name="performerTel"
                type="mask"
                mask="+999999999999"
                label={t("phoneNumber")}
                value={formData.performerTel}
                onChange={handleInputChange}
                disabled={false}
              />
            </div>
          </div>

          <div style={{ display: "flex", marginBottom: "30px" }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: "30px", whiteSpace: "nowrap" }}>
              <input id="draft" type="radio" checked={formData.action_type == "1" ? true : false } style={{ marginRight: "10px" }} value={1} onChange={handleInputChange} name="action_type" />
              <label htmlFor="draft" style={{ fontWeight: "bolder" }}>{t("letter_create_auto")}</label>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginRight: "30px", whiteSpace: "nowrap" }}>
              <input id="ready" type="radio" style={{ marginRight: "10px" }} value={2} onChange={handleInputChange} name="action_type" />
              <label htmlFor="ready" style={{ fontWeight: "bolder" }}>{t("letter_create_upload")}</label>
            </div>
          </div>

          {formData.action_type === "2" && (
            <div style={{ padding: "20px", boxShadow: "0px 4px 20px -4px rgba(0, 0, 0, 0.25)", marginBottom: "30px" }}>
              <h3 className="contractInfo_title">{t("letter_create_upload")}</h3>
              <div style={{ width: "100%" }}>
                <label className="contractInfo_drop-form-block-item-label" htmlFor="">{t("file_attachment")} (.pdf)</label>
                <input name="filePdf" accept='.pdf' onChange={(e) => handleFile(e, -1, 'pdf')}  type="file" />
              </div>
            </div>
          )}

          {formData.action_type !== "2" && (
            renderEmployeeFields()
          )}

          <div className="request_content-buttons" style={{ justifyContent: "flex-end" }}>
            <button
              type="submit"
              onClick={handleSubmit}
              disabled={validation()}
              style={{opacity: validation() ? .5 : 1, marginBottom: "30px"}}
            >
              {formData.action_type === "2" ? t("send") : t("next")}
              <Arrow2 color="#0e0e4b" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EMailContract;
