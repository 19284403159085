import React, {Suspense, useEffect, useState} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import {useAppSelector, useAppDispatch} from "./hooks/redux";
import "./scss/styles";
import AuthPage from "./pages/AuthPage/AuthPage";
import MainPage from "./pages/MainPage/MainPage";
import Header from "./components/Header/Header";
import DataCenterPage from "./pages/DataCenterPage/DataCenterPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import RequestPage from "./pages/RequestPage/RequestPage";
import ContractInfoPage from "./pages/ContractInfoPage/ContractInfoPage";
import AgreementPage from "./pages/AgreementPage/AgreementPage";
import ShowRequestPage from "./pages/ShowRequestPage/ShowRequestPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import FavoritePage from "./pages/FavoritePage/FavoritePage";
import MyRequestPage from "./pages/MyRequestPage/MyRequestPage";
import CodePage from "./pages/CodePage";
import RequestWithoutModal from "./pages/RequestWithoutModal/RequestWithoutModal";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import Application from "./pages/ApplicationPage/Application";
import InterCeptor from "./components/InterCeptor";
import RequestWithoutModalexpertise from "./pages/RequestWithoutModal/RequestWithoutModalexpertise";
import RequestWithoutModalvps from "./pages/RequestWithoutModal/RequestWithoutModalvps";
import VpsPage from "./pages/VPSPage/VpsPage";
import HeaderMobile from "./components/Header/HeaderMobile";
import VpsAgreementAdditionalPage from "./pages/VPSPage/VpsAgreementAdditionalPage";
import MyVm from "./pages/VPSPage/MyVm";
import VpsContractInfo from "./pages/VPSPage/VpsContractInfo";
import VpsAgreement from "./pages/VPSPage/VpsAgreement";
import {getVpsBalance} from "./store/actions/vpsActions";
import NewsPage from "./pages/NewsPage/NewsPage";
import InvoicePage from "./pages/InvoicePage";
import EMailPage from "./pages/EXat/EMail";
import EMailContract from "./pages/EXat/EMailContract";
import EMailContractInfo from "./pages/EXat/EMailContractInfo";
import EMailAgreement from "./pages/EXat/EMailAgreement";
import {getFizUserDetail, getServices} from "./store/actions/contractActions";
import PageNotFound from "./pages/NotFoundPage/PageNotFound";
import RequestWithoutModalColocation from "./pages/RequestWithoutModal/RequestWithoutModalColocation";
import ColocationAgreementAdditionalPage from "./pages/AgreementPage/AgreementAdditionalPage";
import Footer from "./components/Footer/Footer";
import RequestWithoutModalCertification from "./pages/RequestWithoutModal/RequestWithoutModalCertification";
import ContactBack from "./pages/ApplicationPage/ContactBack";
import EMailLetterAgreement from "./pages/EXat/EMailLetterAgriment";
import {HooksCommission} from "./components/eImzoConfig";

const App: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {access} = useAppSelector((state) => state.auth);
  const {services} = useAppSelector((state) => state.contracts);
  const {userDetail} = useAppSelector((state) => state.user)
  
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const {AppLoad} = HooksCommission()

  useEffect(() => {
    AppLoad()
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener("resize", handleResize);
    
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [access, dispatch]);
  
  useEffect(() => {
    if (access) {
      dispatch(getFizUserDetail(access))
    }
  }, [access, dispatch]);
  
  useEffect(() => {
    if (access) {
      dispatch(getVpsBalance(access));
      setTimeout(() => {
        dispatch(getVpsBalance(access));
      }, 60 * 60 * 1000);
    }
    dispatch(getServices(access))
  }, [access, dispatch]);
  
  const headerHandler = () => {
    if (location.pathname === "/") return <Header/>;
    if (location.pathname === "/show-request") return <Header/>;
    const isServicePage = services.some(
      (el) => location.pathname === `/${el.group.slug}`
    );
    if (isServicePage) {
      return <Header/>;
    }
    if (location.pathname === "/service") return <Header/>;
    if (location.pathname === "/ariza") return <Header/>;
    if (location.pathname === "/qayta-aloqa") return <Header/>;
    if (location.pathname === "/request") return <Header/>;
    if (location.pathname === "/contract-info") return <Header/>;
    if (location.pathname === "/agreement") return <Header/>;
    if (location.pathname === "/profile") return <Header/>;
    if (location.pathname === "/favorite") return <Header/>;
    if (location.pathname === "/my-request") return <Header/>;
    if (location.pathname === "/vps") return <Header/>;
    if (location.pathname === "/vpsAgreementAdditional") return <Header/>;
    if (location.pathname === "/my-vm") return <Header/>;
    if (location.pathname === "/vpsContractInfo") return <Header/>;
    if (location.pathname === "/vpsAgreement") return <Header/>;
    if (location.pathname === "/news") return <Header/>;
    if (location.pathname === "/e-xat") return <Header/>;
    if (location.pathname === "/emailContract") return <Header/>;
    if (location.pathname === "/emailLetterAgreement") return <Header/>;
    if (location.pathname === "/emailContractInfo") return <Header/>;
    if (location.pathname === "/emailContractAgreement") return <Header/>;
    if (location.pathname === "/EMailContract") return <Header/>;
    if (location.pathname === "/colocationAgreementAdditional") return <Header/>;
    else return null;
  };
  
  const headerMobileHandler = () => {
    if (location.pathname === "/") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/show-request") return <HeaderMobile type={'main'}/>;
    const isServicePage = services.some(
      (el) => location.pathname === `/${el.group.slug}`
    );
    if (isServicePage) {
      return <HeaderMobile type={'main'}/>;
    }
    if (location.pathname === "/service") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/ariza") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/qayta-aloqa") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/request") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/contract-info") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/agreement") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/profile") return <HeaderMobile type={'profile'}/>;
    if (location.pathname === "/favorite") return <HeaderMobile type={'profile'}/>;
    if (location.pathname === "/my-request") return <HeaderMobile type={'profile'}/>;
    if (location.pathname === "/vps") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/vpsAgreementAdditional")
      return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/my-vm") return <HeaderMobile type={'profile'}/>;
    if (location.pathname === "/vpsContractInfo") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/vpsAgreement") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/news") return <HeaderMobile type={'profile'}/>;
    if (location.pathname === "/e-xat") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/emailContract") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/emailLetterAgreement") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/emailContractInfo") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/emailContractAgreement") return <HeaderMobile type={'main'}/>;
    if (location.pathname === "/colocationAgreementAdditional") return <HeaderMobile type={'main'}/>;
    else return null;
  };
  
  const checkRoute = () => {
    if (
      // eslint-disable-next-line no-mixed-operators
      !userDetail && (location.pathname === '/profile' ||
        location.pathname === '/my-request' ||
        location.pathname === '/news' ||
        location.pathname === '/my-vm' ||
        location.pathname === '/auth' ||
        location.pathname === "/favorite")) {
      return true
    } else {
      return false
    }
  }
  
  return (
    <>
      {windowWidth > 768 ? headerHandler() : headerMobileHandler()}
      <InterCeptor/>
      <Suspense fallback={<LoadingPage/>}>
        {checkRoute() ? (
          <>
            <Routes>
              <Route path="/" element={<MainPage/>}/>
              {services &&
                services.map((el, index) => (
                  <Route
                    key={index}
                    path={`/${el.group.slug}`}
                    element={<DataCenterPage/>}
                  />
                ))}
              <Route path="/service/:id" element={<ServicePage/>}/>
              <Route path={'*'} element={<PageNotFound/>}/>
              <Route path={'/auth'} element={<AuthPage/>}/>
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/" element={<MainPage/>}/>
              {services &&
                services.map((el) => (
                  <Route
                    key={el.group.slug}
                    path={`/${el.group.slug}`}
                    element={<DataCenterPage/>}
                  />
                ))
              }
              <Route path="/service/:id" element={<ServicePage/>}/>
              <Route path="/ariza" element={<Application/>}/>
              <Route path="/qayta-aloqa" element={<ContactBack/>}/>
              <Route path="/show-request/:id" element={<ShowRequestPage/>}/>
              <Route path="/profile" element={<ProfilePage/>}/>
              <Route path="/favorite" element={<FavoritePage/>}/>
              <Route path="/my-request" element={<MyRequestPage/>}/>
              <Route path="/news" element={<NewsPage/>}/>
              <Route path="/code" element={<CodePage/>}/>
              <Route path="/in" element={<InvoicePage/>}/>
              <Route path="/in/code" element={<InvoicePage/>}/>

              {/*vps routes*/}
              <Route path="/vps" element={<VpsPage/>}/>
              <Route path="/vpsContractInfo" element={<VpsContractInfo/>}/>
              <Route path="/vpsAgreementAdditional" element={<VpsAgreementAdditionalPage/>}/>
              <Route path="/vpsAgreement" element={<VpsAgreement/>}/>
              <Route path="/my-vm" element={<MyVm/>}/>
              <Route path="/show-my-request/:id" element={<RequestWithoutModal/>}/>
              <Route path="/show-my-request-colocation/:id" element={<RequestWithoutModalColocation/>}/>
              <Route path="/show-my-request-expertise/:id" element={<RequestWithoutModalexpertise/>}/>
              <Route path="/show-my-request-vps/:id" element={<RequestWithoutModalvps/>}/>
              <Route path="/show-my-request-tte_certification/:id" element={<RequestWithoutModalCertification/>}/>

              {/*colocation routes*/}
              <Route path="/contract-info" element={<ContractInfoPage/>}/>
              <Route path="/request" element={<RequestPage/>}/>
              <Route path="/agreement" element={<AgreementPage/>}/>
              <Route path="/colocationAgreementAdditional" element={<ColocationAgreementAdditionalPage/>} />

              {/*e-xat routes*/}
              <Route path={'/e-xat'} element={<EMailPage/>}/>
              <Route path={"/emailContract"} element={<EMailContract/>}/>
              <Route path={"/emailLetterAgreement"} element={<EMailLetterAgreement/>}/>
              <Route path={"/emailContractInfo"} element={<EMailContractInfo/>}/>
              <Route path={"/emailContractAgreement"} element={<EMailAgreement/>}/>

              <Route path={'*'} element={<PageNotFound/>}/>
            </Routes>
          </>
        )}
      </Suspense>
      {location.pathname !== '/auth' && <Footer />}
    </>
  );
};

export default App;
