import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AllIcon from "../../images/allIcon";
import FavoriteIcon from "../../images/favoriteIcon";
import LogoutIcon from "../../images/logoutIcon";
import MyInfoIcon from "../../images/myInfoIcon";
import MyRequestIcon from "../../images/MyRequestIcon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { clearAuthData, logoutHandler } from "../../store/actions/authActions";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";
import { getGroupList } from "../../store/actions/accountActions";
import { iGroupList } from "../../store/models";
import { cleanUserDetails } from "../../store/actions/contractActions";
import { useTranslation } from "react-i18next";
import VmActiveImg from "../../images/vmActiveImg";
import VmInActive from "../../images/VmInActive";

type Props = {
  active: string;
  type: "main" | "profile";
};

const Sidebar: React.FC<Props> = ({ active, type }) => {
  const naigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const arr = [
    {
      id: 1,
      name: t("myContracts"),
      icon: <MyRequestIcon bgColor="#0E0E4B" iconColor="#ffffff" />,
      activeIcon: <MyRequestIcon bgColor="#ffffff" iconColor="#0E0E4B" />,
      navigate: "/my-request",
    },
    {
      id: 5,
      name: t("myVm"),
      icon: <VmInActive/>,
      activeIcon: <VmActiveImg/>,
      navigate: "/my-vm",
    },
    {
      id: 2,
      name: t("savedServices"),
      icon: <FavoriteIcon bgColor="#0E0E4B" iconColor="#ffffff" />,
      activeIcon: <FavoriteIcon bgColor="#ffffff" iconColor="#0E0E4B" />,
      navigate: "/favorite",
    },
    {
      id: 4,
      name: t("news"),
      icon: <MyInfoIcon bgColor="#0E0E4B" iconColor="#ffffff" />,
      activeIcon: <MyInfoIcon bgColor="#ffffff" iconColor="#0E0E4B" />,
      navigate: "/news",
    },
    {
      id: 3,
      name: t("myInfo"),
      icon: <MyInfoIcon bgColor="#0E0E4B" iconColor="#ffffff" />,
      activeIcon: <MyInfoIcon bgColor="#ffffff" iconColor="#0E0E4B" />,
      navigate: "/profile",
    },
  ];

  const { response, token, access } = useAppSelector((state) => state.auth);
  const { groupList, loading } = useAppSelector((state) => state.accounts);
  const { userDetail } = useAppSelector((state) => state.user);

  const handleNavigate = (slug: string, id: any) => {
    naigate(`/${slug}`, { state: { groupId: id } });
  };
  
  const logout = () => {
    const refresh = JSON.stringify(localStorage.getItem('refresh_token'))
    const body = {
      response,
      token,
      access,
    };
    naigate("/");
    dispatch(logoutHandler(body, access, JSON.parse(refresh ? refresh : '[]')));
    // dispatch(logoutHandler(body, access, refresh));
    dispatch(clearAuthData(body));
    dispatch(cleanUserDetails(userDetail));
    localStorage.clear()
  };

  useEffect(() => {
    dispatch(getGroupList());
  }, []);
  
  if (loading) return <LoadingPage />;

  return (
    <div className="sidebar" style={{width: type === 'profile' ? '18%' : '', flex: type === 'profile' ? undefined : '2'}}>
      <div className="sidebar_menu">
        {type === "main" && (
          <div className={`${active === `/` ? 'sidebar_menu-active sidebar_menu-item' : 'sidebar_menu-item'}`} onClick={() => naigate("/")}>
            {active !== "/" && <div className="hover"></div>}
            <div
              className={
                active === "/"
                  ? "sidebar_menu-item-active"
                  : "sidebar_menu-item-icon"
              }
            >
              {active === "/" ? (
                <AllIcon bgColor="#ffffff" iconColor="#0E0E4B" />
              ) : (
                <AllIcon bgColor="#0E0E4B" iconColor="#ffffff" />
              )}
            </div>
            <div className={`sidebar_menu-item-name ${active === `/` ? "active" : 'inactive'} `}>{t("allServices")}</div>
          </div>
        )}
        {type === "main" &&
          groupList?.map((el: iGroupList, index) => (
            <div
              className={el.is_visible_in_front == true ? `sidebar_menu-item ${active === `/${el.slug}` ? 'sidebar_menu-active' : ''}` : "visually-hidden"}
              key={index}
              onClick={() => handleNavigate(el.slug, el.id)}
            >
              {/*{active !== `/${el.slug}` && <div className="hover"></div>}*/}
              <div
                className={
                  active === `/${el.slug}`
                    ? "sidebar_menu-item-active"
                    : "sidebar_menu-item-icon"
                }
              >
                {active === `/${el.slug}`
                  ? <img src={el.active_icon} alt=""/>
                  : <img src={el.inactive_icon} alt=""/>
                }
              </div>
              <div className={`sidebar_menu-item-name ${active === `/${el.slug}` ? "active" : 'inactive'} `}>{el.name}</div>
            </div>
          ))}
        {type === "profile" &&
          arr?.map((el, index) => (
            <div
              className={`sidebar_menu-item ${active === `${el.navigate}` ? 'sidebar_menu-active' : ''}`}
              key={index}
              onClick={() => naigate(el.navigate)}
            >
              {/*{active !== `/${el.slug}` && <div className="hover"></div>}*/}
              <div
                className={
                  active === `${el.navigate}`
                    ? "sidebar_menu-item-active"
                    : "sidebar_menu-item-icon"
                }
              >
                {active === `${el.navigate}`
                  ? <div>{el.activeIcon}</div>
                  : <div>{el.icon}</div>
                }
              </div>
              <div className={`sidebar_menu-item-name ${active === `${el.navigate}` ? "active" : 'inactive'} `}>{el.name}</div>
            </div>
          ))}
        {type === "profile" && (
          <div className="sidebar_menu-item" onClick={logout}>
            {active !== "/" && <div className="hover"></div>}
            <div className={"sidebar_menu-item-icon"}>
              <LogoutIcon bgColor="#0E0E4B" iconColor="#ffffff" />
            </div>
            <div className="sidebar_menu-item-name">{t("logout")}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
